


































import {Component, Prop, Vue} from 'vue-property-decorator';
import {twoChars} from '@/utils/helpers';
import AvatarWithStatus from '@/components/AvatarWithStatus.vue';
import ContactInfoCard from '@/components/ContactInfoCard.vue';
import {BusinessContact} from '@/domain/model/types';
import {applicationStore} from '@/store/modules/application';
import PopupMenu from '@/components/PopupMenu.vue';

@Component({
  name: 'backing-contact-card',
  components: {PopupMenu, ContactInfoCard, AvatarWithStatus}
})
export default class BackingContactCard extends Vue {
  @Prop({
    default: function() {
      return {};
    }
  }) contact!: BusinessContact;

  showDeleteContact: boolean = false;

  get isAdmin() {
    return applicationStore.isAdmin
  }

  get photoUrl() {
    return this.contact.photoUrl && this.contact.photoUrl.normal;
  }

  get online() {
    const associate = this.contact.associate;
    return associate && associate.status && associate.status.online;
  }

  get name() {
    return this.contact.name;
  }

  get position() {
    return this.contact.position || this.contact.description || 'Associate';
  }

  get abbreviation() {
    return twoChars(this.name);
  }

  get menu() {
    return [
      {
        title: 'Share',
        index: 0
      },
      {
        title: 'Delete',
        index: 1,
        skip: !this.isAdmin,
        alert: true
      }
    ];
  }

  onMenuItemClicked(menuItem) {
    switch (menuItem.index) {
      case 0: {
        this.$emit('on-share-backing-contact', this.contact.id);
        break;
      }
      case 1: {
        this.showDeleteContact = true;
        break;
      }
      default:
    }
  }
}
