






















import {Component, Prop, Vue} from 'vue-property-decorator';
import BaseDialog from '@/components/BaseDialog.vue';
import ContactsList from '@/components/ContactsList.vue';
import {Action, Getter} from 'vuex-class';
import {BusinessContact} from '@/domain/model/types';
import {applicationStore} from '@/store/modules/application';
import {profileStore} from '@/store/modules/profile';

@Component({
  name: 'dialog-add-backup-contact',
  components: {BaseDialog, ContactsList}
})
export default class DialogAddBackupContact extends Vue {
  @Prop() value!: boolean;

  @Getter selectedContact;
  @Getter backupContacts;

  loading: boolean = false;
  selectedContacts: BusinessContact[] = [];

  @Action addContactsToBackupList;

  get businessId() {
    return applicationStore.businessId;
  }

  get t2bUser() {
    return profileStore.t2bUser;
  }

  get show() {
    return this.value;
  }

  set show(value: boolean) {
    this.$emit('input', value);
  }

  get filterIDs() {
    const ids = this.backupContacts.map((item) => item.uid);
    return [this.selectedContact.associate.id, ...ids];
  }

  onContactsSelected(contacts: BusinessContact[]) {
    this.selectedContacts = contacts;
  }

  async onAddSelectedContactsToBackupList() {
    if (!this.selectedContacts.length) {
      console.log('No contacts selected');
      return;
    }
    this.loading = true;
    try {
      await this.addContactsToBackupList({
        contact: this.selectedContact,
        contacts: this.selectedContacts
      });
      this.selectedContacts = [];
      this.show = false;
      console.log('Backup contacts saved');
    } catch (err) {
      console.log(`Save backup contacts. error: ${err}`);
    }
    this.loading = false;
  }
}
