




































































































































































import {Component, Watch} from 'vue-property-decorator';
import ContactInfoCard from '../ContactInfoCard.vue';
import VcSwitch from '../VCSwitch.vue';
import {formatMuted} from '@/utils/helpers';
import DaySettings from '@/components/DaySettings.vue';
import AssignedAssistantCard from '@/components/profile/AssignedAssistantCard.vue';
import DialogNotificationSettings from '@/components/profile/DialogNotificationSettings.vue';
import DialogMuteTimeSettings from '@/components/profile/DialogMuteTimeSettings.vue';
import DialogAddAppointmentAssistant from '@/components/profile/DialogAddAppointmentAssistant.vue';
import {profileStore} from '@/store/modules/profile';
import {settingsStore} from '@/store/modules/settings';
import {mixins} from 'vue-class-component';
import ContainerCalc from '@/components/mixins/ContainerCalc';

@Component({
  name: 'profile-info-permissions',
  components: {
    DialogAddAppointmentAssistant,
    DialogMuteTimeSettings,
    DialogNotificationSettings, AssignedAssistantCard, DaySettings, VcSwitch, ContactInfoCard
  },
  filters: {
    formatMuted
  }
})
export default class ProfileInfoPermissions extends mixins(ContainerCalc) {
  showDisableAppointsDialog: boolean = false
  showDisableInternalCallsDialog: boolean = false
  showDisableCustomerCallsDialog: boolean = false
  showDisableAffiliateCallsDialog: boolean = false
  showAddAssistantDialog: boolean = false
  disableInternalCalls: boolean = false
  disableCustomerCalls: boolean = false
  disableAffiliateCalls: boolean = false

  times: any[] = [
    {name: '10 min', value: 10},
    {name: '15 min', value: 15},
    {name: '20 min', value: 20},
    {name: '30 min', value: 30},
    {name: '1 hr', value: 60}
  ]

  excludeFromTime: string = settingsStore.excludeFromTime
  excludeToTime: string = settingsStore.excludeToTime

  get disableAppoints() {
    return profileStore.disableAppoints
  }

  get appointsDisabledByBusiness() {
    return !settingsStore.businessSettings?.appoints?.active
  }

  get appointsDisabled() {
    return this.appointsDisabledByBusiness || !!this.disableAppoints
  }

  get assignedAssistants() {
    return profileStore.assistants
  }

  get appointDays() {
    return profileStore.appointDays
  }

  get timePeriod() {
    return profileStore.period
  }

  set timePeriod(period: number) {
    profileStore.setTimePeriod(period)
  }

  @Watch('disableAppoints')
  onChangeDisableAppoints(after) {
    if (after) {
      const nowMillis = new Date().getTime()
      const targetMillis = after.toMillis();
      const difHours = (targetMillis - nowMillis) / (60 * 60 * 1000);
      if (difHours <= 0) {
        profileStore.disableAppointments(0)
      }
    }
  }

  appointDayFrom(index: number) {
    const start = settingsStore?.businessSettings?.appoints?.appointDays[index]?.start;
    return start ? start : this.excludeFromTime
  }

  appointDayTo(index: number) {
    const end = settingsStore?.businessSettings?.appoints?.appointDays[index]?.end;
    return end ? end : this.excludeToTime
  }

  appointDayDisabled(index: number) {
    return !settingsStore?.businessSettings?.appoints?.appointDays[index]?.active
  }

  onRemoveAssistant(associateId: string) {
    profileStore.removeAssistant(associateId)
  }

  onDisableAppointsTimeSelected(duration: number = 0) {
    profileStore.disableAppointments(duration)
  }

  onStartChanged(day) {
    profileStore.changeAppointDay(day)
  }

  onEndChanged(day) {
    profileStore.changeAppointDay(day)
  }

  onActiveChanged(day) {
    profileStore.changeAppointDay(day)
  }

  onPeriodChanged(period: number) {
    profileStore.setAppointDaysPeriod(period)
  }

  onDisableAppointsToggle() {
    if (!!this.disableAppoints) {
      this.onDisableAppointsTimeSelected()
      return
    }
    this.showDisableAppointsDialog = !this.appointsDisabledByBusiness
  }

  created() {
    profileStore.loadPermissionsSettings()
  }

  mounted() {
    this.calcHeight(this.$refs.permissionsContainer)
  }
}
