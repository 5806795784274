














import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  name: 'contact-info-card'
})
export default class ContactInfoCard extends Vue {
  @Prop() title?: string;
  @Prop({default: false}) inset?: boolean;
  @Prop({default: true}) actions?: boolean;
}
