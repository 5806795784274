





















































































































import {Component, Prop} from 'vue-property-decorator';
import AvatarWithStatus from '@/components/AvatarWithStatus.vue';
import {copyTextToClipboard, twoChars} from '@/utils/helpers';
import ContactInfoCard from '@/components/ContactInfoCard.vue';
import VcSwitch from '@/components/VCSwitch.vue';
import ContactInfoGeneral from '@/components/ContactInfoGeneral.vue';
import ContactInfoAssignment from '@/components/ContactInfoAssignment.vue';
import {applicationStore} from '@/store/modules/application';
import DialogEditProfile from '@/components/profile/DialogEditProfile.vue';
import ProfileInfoGeneral from '@/components/profile/ProfileInfoGeneral.vue';
import ProfileInfoWorkingStatus from '@/components/profile/ProfileInfoWorkingStatus.vue';
import {profileStore} from '@/store/modules/profile';
import ProfileInfoAssignment from '@/components/profile/ProfileInfoAssignment.vue';
import ProfileInfoPermissions from '@/components/profile/ProfileInfoPermissions.vue';
import PopupMenu from '@/components/PopupMenu.vue';
import {businessStore} from '@/store/modules/business/businessStore';
import GpHeader from '@/components/custom/HeaderView.vue';
import UserProfileGeneral from '@/components/business/users/UserProfileGeneral.vue';
import DialogConfirm from '@/components/DialogConfirm.vue';
import Notifications from '@/components/mixins/Notifications';
import {mixins} from 'vue-class-component';
import ContainerCalc from '@/components/mixins/ContainerCalc';

Component.registerHooks(['beforeRouteEnter'])

@Component({
  name: 'user-info',
  components: {
    DialogConfirm,
    UserProfileGeneral,
    GpHeader,
    PopupMenu,
    ProfileInfoPermissions,
    ProfileInfoAssignment,
    ProfileInfoWorkingStatus,
    ProfileInfoGeneral,
    DialogEditProfile,
    ContactInfoAssignment,
    ContactInfoGeneral,
    VcSwitch, ContactInfoCard, AvatarWithStatus
  }
})
export default class UserInfo extends mixins(Notifications, ContainerCalc) {
  @Prop({type: String, required: true}) public userId

  public showEditProfileDialog: boolean = false;
  public showDeleteProfile: boolean = false;
  public selectedTab: number = 0;
  public infoMessage: string = '';
  public timeout: number = 3000;
  public onOkClicked?: any;
  public dialog: boolean = false;
  public alertTitle: string = '';
  public alertText: string = '';
  public hidden: boolean = false
  public disabled: boolean = false
  public tabs: string[] = ['General', 'Assignments', 'Statistics']

  get menu() {
    return [
      {
        title: 'Enable',
        skip: !this.disabled
      },
      {
        title: 'Disable',
        skip: this.disabled || this.selectedUser?.roles?.superAdmin === true
      },
      {
        title: 'Show',
        skip: !this.hidden
      },
      {
        title: 'Hide',
        skip: this.hidden
      },
      {
        title: 'Remove',
        skip: this.selectedUser?.roles?.superAdmin === true,
        alert: true
      }
    ]
  }

  get selectedUser() {
    return businessStore.selectedUser
  }

  get title(): string {
    return this.selectedUser?.fullName || 'Associate';
  }

  get subtitle(): string {
    return this.selectedUser?.position || 'Associate';
  }

  get photoUrl(): string {
    return this.selectedUser?.photoUrl?.normal || '';
  }

  get abbreviation() {
    return twoChars(this.title);
  }

  get rating() {
    return (this.selectedUser?.stats?.avgRating || 0.0).toFixed(2);
  }

  get reviewsCount() {
    return this.selectedUser?.stats?.cases || 0;
  }

  public onTabChanged(index: number) {
    this.$router.push({name: 'user-info', query: {tab: this.tabs[index]}});
  }

  public onMenuItemClicked(menuItem) {
    switch (menuItem.title) {
      case 'Enable': {
        this.onEnableUser()
        break
      }
      case 'Disable': {
        this.onDisableUser()
        break
      }
      case 'Show': {
        this.onShowUser()
        break
      }
      case 'Hide': {
        this.onHideUser()
        break
      }
      case 'Remove': {
        this.showDeleteProfile = true;
        break
      }
      default:
    }
  }

  public onEnableUser() {
    this.alertTitle = 'Enable User';
    this.alertText = `Confirm enabling user '${this.selectedUser?.fullName}' ?`;
    this.onOkClicked = async () => {
      this.dialog = false;
      try {
        await applicationStore.disableUser({uid: this.selectedUser?.id, disable: false});
        this.disabled = false
        this.showToast(`${this.selectedUser?.fullName} was enabled`);
      } catch (e: any) {
        this.showErrorToast(e.message);
      }
    };
    this.dialog = true;
  }

  public onDisableUser() {
    this.alertTitle = 'Disable User';
    this.alertText = `Confirm disabling user '${this.selectedUser?.fullName}' ?`;
    this.onOkClicked = async () => {
      this.dialog = false;
      try {
        await applicationStore.disableUser({uid: this.selectedUser?.id, disable: true});
        this.disabled = true
        this.showToast(`${this.selectedUser?.fullName} was disabled`);
      } catch (e: any) {
        this.showErrorToast(e.message);
      }
    };
    this.dialog = true;
  }

  public onHideUser() {
    this.alertTitle = 'Hide User';
    this.alertText = `Confirm hiding user '${this.selectedUser?.fullName}' ?`;
    this.onOkClicked = async () => {
      this.dialog = false;
      try {
        await applicationStore.hideUser({uid: this.selectedUser?.id, hidden: true});
        this.hidden = true
        this.showToast(`${this.selectedUser?.fullName} was hidden`);
      } catch (e: any) {
        this.showErrorToast(e.message);
      }
    };
    this.dialog = true;
  }

  public onShowUser() {
    this.alertTitle = 'Show User';
    this.alertText = `Confirm showing user '${this.selectedUser?.fullName}' ?`;
    this.onOkClicked = async () => {
      this.dialog = false;
      try {
        await applicationStore.hideUser({uid: this.selectedUser?.id, hidden: false});
        this.hidden = false
        this.showToast(`${this.selectedUser?.fullName} was unhidden`);
      } catch (e: any) {
        this.showErrorToast(e.message);
      }
    };
    this.dialog = true;
  }

  public async onRemoveUser() {
    this.showDeleteProfile = false;
    try {
      await applicationStore.deleteUser(this.selectedUser?.id);
      this.showToast(`${this.selectedUser?.fullName} was deleted`);
    } catch (e: any) {
      this.showErrorToast(e.message);
    }
  };

  public async shareProfile() {
    if (!this.selectedUser) {
      return;
    }
    try {
      const shortLink = await profileStore.shareProfileLink(this.userId);
      await copyTextToClipboard(shortLink);
      this.showToast('Copied to clipboard');
    } catch (err: any) {
      console.log(`share contact. error: ${err}`);
      this.$emit('on-error', err.message);
    }
  }

  public onChangesSaved() {
    const notification: any = this.$refs.notification
    notification.show('info', 'Changes saved')
  }

  public showToast(text) {
    this.showInfo(text);
  }

  public showErrorToast(error) {
    this.showIssue(error);
  }

  public created() {
    this.selectedTab = this.tabs.indexOf(this.$route.query.tab as string)
  }

  public mounted() {
    this.calcHeight(this.$refs.generalTab)
    this.calcHeight(this.$refs.assignmentTab)
  }

  public updated() {
    this.selectedTab = this.tabs.indexOf(this.$route.query.tab as string)
  }

  public async beforeRouteEnter(to, from, next) {
    await businessStore.loadUser(to.params.userId)
    next((vm) => {
      vm.hidden = vm.selectedUser?.hidden
      vm.disabled = vm.selectedUser?.disabled
    })
  }
}
